import React from 'react';
import handleSubscribe from '../../common/newsletter/subscribe';

const M1Upgrade = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'

    const handleSubmit = (event) => {
        //Prevent default
        event.preventDefault();

        //Grab email from url params
        const urlParams = new URLSearchParams(window.location.search);
        let email = urlParams.get('email');

        if (!email) {
            email = "";
        }

        handleSubscribe(email, true,
            function (message) {
                console.log('Success:', message);

                //Hide any error messages
                document.querySelector('.form-msg.bg-danger').classList.add('d-none');

                //Show success message
                const successMsg = document.querySelector('.form-msg.bg-success');
                successMsg.innerHTML = message;
                successMsg.classList.remove('d-none');
            },
            function (message) {
                console.error('Error:', message);

                //Hide any success messages
                document.querySelector('.form-msg.bg-success').classList.add('d-none');

                //Show error message
                const errorMsg = document.querySelector('.form-msg.bg-danger');
                errorMsg.innerHTML = message;
                errorMsg.classList.remove('d-none');
            });
    };

    return (
        <>
            <section className="landing-page moisture">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 order-1 order-lg-0">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <div className="sec-title__tagline">
                                        <h6>PlantCare VIP Member</h6>
                                        <span className="right"></span>
                                    </div>
                                    <h2 className="sec-title__title">Become A VIP Member Today!</h2>
                                    <br />
                                    <p>
                                        As a VIP to PlantCare, you will receive:
                                    </p>
                                    <ul className="list-check">
                                        <li>✓ 5 Sample seed packs for beginners</li>
                                        <li>✓ Free Journal for garden notes</li>
                                        <li>✓ Exclusive VIP discounts</li>
                                        <li>✓ Access to our PlantCare community</li>
                                        <li>✓ Early Launch Access</li>
                                        <li>✓ 3 months of PRO subscription</li>
                                        <li>✓ Early access to our platform</li>
                                        <li>✓ Beta testing queue</li>
                                    </ul>
                                </div>

                                <form id="contact-form" className="default-form2 contact-form-validated" action="#" novalidate="novalidate">
                                    <div id="status-container" className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="form-msg p-2 m-2 bg-success border border-secondary rounded text-white d-none">.bg-success</div>
                                                <div className="form-msg p-2 m-2 bg-danger border border-danger rounded text-white d-none">.bg-danger</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="contact-one__form-btn">
                                                <button className="thm-btn" type="submit" onClick={handleSubmit} data-loading-text="Please wait...">
                                                    <span className="txt">Unlock for just $5</span>&nbsp;
                                                    <i className="fa fa-angle-double-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4 order-0 order-lg-1">
                            <div className="landing-page__img">
                                <img src={publicUrl + "assets/images/moisture-1/plant-stand-1.1.jpg"} alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features-two">
                <div className="features-two__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/features-v2-bg.png)' }}></div>
                <div className="auto-container">
                    <div className="row">
                        {/* Start Features Two Single */}
                        <div className="col-lg-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div className="features-two__single">
                                <div className="features-two__single-content">
                                    <div className="count-box">
                                        01
                                    </div>
                                    <div className="inner features-square">
                                        <div className="icon-box">
                                            <span className="icon-tick"></span>
                                        </div>

                                        <div className="content-box">
                                            <div className="title">
                                                <h2>100% Refund Guarantee</h2>
                                            </div>
                                            <p>
                                                Change your mind, get a refund anytime before we enter production.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Features Two Single */}

                        {/* Start Features Two Single */}
                        <div className="col-lg-4 wow fadeInRight" data-wow-delay="100ms" data-wow-duration="1500ms">
                            <div className="features-two__single">
                                <div className="features-two__single-content">
                                    <div className="count-box">
                                        02
                                    </div>
                                    <div className="inner features-square">
                                        <div className="icon-box">
                                            <span className="icon-tick"></span>
                                        </div>

                                        <div className="content-box">
                                            <div className="title">
                                                <h2>Transparency Guarantee</h2>
                                            </div>
                                            <p>
                                                We will be transparent about the progress of our project throughout the entire campaign.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Features Two Single */}

                        {/* Start Features Two Single */}
                        <div className="col-lg-4 wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div className="features-two__single">
                                <div className="features-two__single-content">
                                    <div className="count-box">
                                        03
                                    </div>
                                    <div className="inner features-square">
                                        <div className="icon-box">
                                            <span className="icon-tick"></span>
                                        </div>

                                        <div className="content-box">
                                            <div className="title">
                                                <h2>Secure/Private</h2>
                                            </div>
                                            <p>
                                                All orders are processed with out well known and established credit card processor. Your cards are processed with security first in mind.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Features Two Single */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default M1Upgrade;