import React from 'react';

export default class AboutOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="features-two">
                    <div className="features-two__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/features-v2-bg.png)'}}></div>
                    <div className="auto-container">
                        <div className="row">
                            {/* Start Features Two Single */}
                            <div className="col-xl-8 col-lg-8 offset-lg-2">
                                <p>Welcome to PlantCare, your essential gardening companion! Whether you're nurturing a windowsill herb garden or a backyard vegetable patch, PlantCare simplifies plant care with intuitive tracking and expert advice. Seamlessly monitor your plants' progress, receive personalized tips, and connect with a vibrant gardening community. Cultivate your green thumb with PlantCare today.</p>

                                <ol className='mt-5'>
                                    <li>1. Simplify plant care with intuitive tracking features.</li>
                                    <li>2. Access expert advice tailored to your garden's needs.</li>
                                </ol>
                            </div>
                            {/* End Features Two Single */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}