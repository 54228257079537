import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from 'wowjs';
import { FaAngleUp } from "react-icons/fa";
import ReactGA from 'react-ga';

//Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy"
import M1Landing from "./pages/Moisture-1";
import M1Upgrade from "./pages/Moisture-1/upgrade";
import M1Thankyou from "./pages/Moisture-1/thank-you";
import ScrollToTopRoute from './components/scroll-to-top-route/ScrollToTopRoute';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';

function Redirect(path) {
  console.log('here', path);
  return <Navigate to={`${process.env.PUBLIC_URL}/${path}`} replace />;
}

ReactGA.initialize('G-Y001B4QEC0');

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, []
  );
  return (
    <Router>
      <ScrollToTopRoute />
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} exact element={<Home />} />
        <Route path={`${process.env.PUBLIC_URL}/about`} exact element={<About />} />
        <Route path={`${process.env.PUBLIC_URL}/faq`} exact element={<Faq />} />
        <Route path={`${process.env.PUBLIC_URL}/contact`} exact element={<Contact />} />
        <Route path={`${process.env.PUBLIC_URL}/terms`} exact element={<Terms />} />
        <Route path={`${process.env.PUBLIC_URL}/privacy`} exact element={<Privacy />} />

        <Route path={`${process.env.PUBLIC_URL}/moisture-1`} exact component={Redirect('moisture-one')} />
        <Route path={`${process.env.PUBLIC_URL}/moisture-1/`} exact component={Redirect('moisture-one')} />
        <Route path={`${process.env.PUBLIC_URL}/moisture-one/`} exact element={<M1Landing />} />
        <Route path={`${process.env.PUBLIC_URL}/moisture-1/upgrade`} exact component={Redirect('moisture-one/upgrade')} />
        <Route path={`${process.env.PUBLIC_URL}/moisture-one/upgrade`} exact element={<M1Upgrade />} />
        <Route path={`${process.env.PUBLIC_URL}/moisture-1/thank-you`} exact component={Redirect('moisture-one/thank-you')} />
        <Route path={`${process.env.PUBLIC_URL}/moisture-one/thank-you`} exact element={<M1Thankyou />} />

      </Routes>
      <ScrollToTop className="scrollUp" smooth top="1500" component={<FaAngleUp />} />
    </Router>
  );
}

export default App;