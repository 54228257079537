import React from 'react';

const M1Thankyou = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <>
            <section className="landing-page-full">
                <div className="container moisture">
                    <div className="row">
                        <div className="col-lg-8 order-1 order-lg-0">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <div className="sec-title__tagline">
                                        <h6>PlantCare VIP</h6>
                                        <span className="right"></span>
                                    </div>
                                    <h2 className="sec-title__title">Congrats! You’re a VIP!</h2>
                                    <br />
                                    <p>
                                        We will notify you when the early access is ready. In the meantime, you can join our PlantCare community on Facebook.
                                    </p>
                                </div>

                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="contact-one__form-btn">
                                            <a href="https://www.facebook.com/groups/3553077875004114/" className="thm-btn">
                                                <span className="txt">Join PlantCare VIP Community</span>&nbsp;
                                                <i className="fa fa-angle-double-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 order-0 order-lg-1">
                            <div className="landing-page__img">
                                <img src={publicUrl + "assets/images/moisture-1/plant-stand-many.jpg"} alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default M1Thankyou;