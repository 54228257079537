const handleSubscribe = (email, isVip, onSuccess, onError) => {
    var bodyData = { email: email }
    if (isVip) {
        bodyData.vip = "true";
    }

    //Add any utm tags in the url to bodyData
    const urlParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
        if (key.startsWith("utm_")) { 
            bodyData[key] = value;
        }
    }

    //Get the page url withouth params to add to bodyData
    const url = window.location.href;
    const urlWithoutParams = url.split("?")[0];
    bodyData.page = urlWithoutParams;

    // Send API POST request
    fetch('https://api.plantcare.app/api/v1/newsletter', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
    })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                if (data.redirect) {
                    window.location.href = data.redirect; // Redirect if provided
                } else {
                    onSuccess(data.message); // Call onSuccess callback with message
                }
            } else {
                onError(data.message); // Call onError callback with message
            }
        })
        .catch(error => {
            console.error('Network error:', error);
            onError('Network error. Please try again.'); // Call onError callback for network errors
        });
};

export default handleSubscribe;
