import React from "react";
import Header from "../common/header/Header";
import Hero from "../components/home/Hero";
import Product from "../components/product/Product";
import Footer from "../common/footer/Footer";

const Home = () => {
    return (
        <>
            <Header />
            <Hero />
            <Product />
            <Footer />
        </>
    )
}

export default Home;